import React, { useRef, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';

import ExternalIcon from '@material-ui/icons/Launch';
import { makeStyles } from '@material-ui/core/styles';

import Layout from '../../components/Layout';
import Scrollable from '../../components/Scrollable';
import Title from '../../components/Title';
import ResultsHeader from '../../components/ResultsHeader';
import ResultCard from '../../components/ResultCard';
import ValueList from '../../components/ValueList';
import Card from '../../components/Card';
import Button from '../../components/Button';
import ResultFeedback from '../../components/ResultFeedback';

import { GET_TAXES } from '../../graph/clarity';
import { GET_CUSTOM_JOBS } from '../../graph/jobs';
import { GET_META } from '../../graph/auth';

import getUniqueIndustriesFromJobs from '../../utils/getUniqueIndustriesFromJobs';
import getTaxPeriod from '../../utils/getTaxPeriod';

import useTrackPage from '../../hooks/useTrackPage';

import trustpilot from '../../assets/trustpilot-dark.png';

const useStyles = makeStyles(() => ({
  trustpilotContainer: {
    width: '100%',
    marginTop: '30px',
    display: 'block',
  },

  trustpilotImage: {
    display: 'block',
    width: '100%',
  },
}));

const Taxes = () => {
  useTrackPage('/results/taxes');

  const classes = useStyles();

  const { data } = useQuery(GET_META, {
    fetch: 'cache-only',
  });

  const {
    data: { jobs },
  } = useQuery(GET_CUSTOM_JOBS, {
    variables: {
      isCommonToUsers: false,
    },
    fetchPolicy: 'cache-first',
  });

  const uniqueIndustryList = getUniqueIndustriesFromJobs(jobs);

  const {
    data: { expenses, taxCalculation },
    loading,
  } = useQuery(GET_TAXES, {
    variables: {
      industry: uniqueIndustryList[0].id,
    },
  });

  const ref = useRef(null);
  useEffect(() => {
    if (window.Trustpilot && !loading) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, [loading]);

  const isTaxPeriod = getTaxPeriod();

  const renderContent = () => {
    if (loading) {
      return false;
    }

    const accountantExpense = expenses?.find(
      ({ id }) => id === 'accountant_software',
    );

    return (
      <>
        <Title variant="h3" smallText="For the current tax year">
          Your estimated tax bill
        </Title>
        <ResultCard
          blue
          value={taxCalculation?.fullYearEstimate}
          text="Your estimated total tax"
        />
        <ValueList
          data={[
            {
              id: 'Turnover (Gross Income)',
              value: taxCalculation?.grossIncome,
            },
            {
              id: 'Expenses',
              value: taxCalculation?.expenses,
            },
            {
              id: 'Taxable Income',
              value: taxCalculation?.taxableIncome,
            },
            {
              id: 'Income Tax',
              value: taxCalculation?.incomeTax,
            },
            {
              id: 'National Insurance',
              value: taxCalculation?.nationalInsuranceTax,
            },
            {
              id: 'Estimated Total Tax',
              value: taxCalculation?.fullYearEstimate,
              highlight: true,
            },
          ]}
        />
        {!!accountantExpense && (
          <ResultCard largeMargin {...accountantExpense} />
        )}

        <Card
          center
          title={
            isTaxPeriod
              ? 'Get your tax return reviewed by an accountant from £60'
              : 'Get help with your taxes with Finmo'
          }
          blue
        >
          {isTaxPeriod
            ? 'Need help with tax? Head to Finmo to partner with an accountant and file your return through its easy-to-use tax return portal.'
            : 'Record the right expenses, get personalised help from an accountant, and feel supported by Finmo’s team of friendly experts as you file your Self Assessment with confidence.'}
          <br />
          <br />

          <Button
            midSmall
            marginBottom
            white
            to={data?.user?.anonymous ? '/set-up-login' : '/finmo-connect'}
          >
            {isTaxPeriod ? 'Get started' : 'Get started for free today'}
          </Button>

          <Button
            href="http://finmo.co.uk?utm_source=Clarity&utm_medium=Tool"
            target="_blank"
            rel="noopener noreferrer"
            midSmall
            secondary
            icon
            whiteSecondary
            marginBottom
          >
            Learn more <ExternalIcon />
          </Button>

          <a
            href="https://uk.trustpilot.com/review/finmo.co.uk"
            className={classes.trustpilotContainer}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className={classes.trustpilotImage}
              src={trustpilot}
              alt="Trustpilot"
            />
          </a>
        </Card>
        <ResultFeedback section="taxes" />
      </>
    );
  };

  return (
    <Layout fetching={loading} animateIn>
      <Scrollable>
        <ResultsHeader hideDateRangeSelector title="Taxes" />
        {renderContent()}
      </Scrollable>
    </Layout>
  );
};

export default Taxes;
